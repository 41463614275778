import { Navigate } from 'react-router';
import { Outlet } from 'react-router-dom';
import PrivateElement from 'routes/AppRoutes/privateRoute';
import { analyticLoader } from 'routes/loaders/analyticLoader';
import { analyticsLoader } from 'routes/loaders/analyticsLoader';
import DashboardMain from 'components/templates/DashboardMain';
import Error from 'components/templates/Error';
import About from 'pages/About';
import Analytics from 'pages/Analytics';
import Analytic from 'pages/Analytics/Analytic';
import KeyEvents from 'pages/Analytics/Analytic/Settings/KeyEvents';
import Plan from 'pages/Analytics/Analytic/Settings/Plan';
import SearchConsolePage from 'pages/Analytics/Analytic/Settings/SearchConsole';
import Company from 'pages/Company';
import Contact from 'pages/Contact';
import Home from 'pages/Home';
import PasswordReset from 'pages/PasswordReset';
import Privacy from 'pages/Privacy';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import Terms from 'pages/Terms';

const AppRoutes = [
  {
    path: '/',
    element: <Outlet />,
    errorElement: <Error />,
    children: [
      { path: 'about', element: <About /> },
      { path: 'contact', element: <Contact /> },
      {
        path: 'users',
        children: [
          { path: 'sign_up', element: <SignUp /> },
          { path: 'sign_in', element: <SignIn /> },
          { path: 'password/new', element: <PasswordReset /> },
        ],
      },
      { path: 'company', element: <Company /> },
      { path: 'privacy', element: <Privacy /> },
      { path: 'terms', element: <Terms /> },
      {
        path: 'analytics',
        element: <DashboardMain />,
        children: [
          {
            path: ':analyticId',
            element: <PrivateElement element={<Analytic />} />,
            loader: analyticLoader,
          },
          {
            path: ':analyticId/settings/key_events',
            element: <PrivateElement element={<KeyEvents />} />,
            loader: analyticLoader,
          },
          {
            path: ':analyticId/settings/plan',
            element: <PrivateElement element={<Plan />} />,
            loader: analyticLoader,
          },
          {
            path: ':analyticId/settings/search_console',
            element: <PrivateElement element={<SearchConsolePage />} />,
            loader: analyticLoader,
          },
          {
            path: '',
            element: <PrivateElement element={<Analytics />} />,
            loader: analyticsLoader,
          },
        ],
      },
      { path: '/', element: <Home /> },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
];
export default AppRoutes;
